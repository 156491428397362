html {
    font-family: "REM", sans-serif;
    background-color: #D9E6F4FF;
    overflow-y: scroll;
    margin: 0;
    text-rendering: optimizeLegibility !important;
}

@font-face {
    font-family: "Bebas Neue";
    src: url(../fonts/BebasNeue-Regular.ttf)
}

@font-face {
    font-family: 'REM';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/REM.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --github: rgb(0, 0, 0);
    --blog: rgba(255, 166, 0);
    --switch: rgba(228, 0, 15);
    --mastodonPurple: rgba(93, 80, 231);
    --JGURed: rgba(193, 0, 42);
    --scienceGreen: rgba(122, 184, 22);
    --twitterBlue: #1da1f2;
}

:root {
    --dark: #43494E;
    --light: #FFFFFF;
    --lightPurple: #d6bfddff;
    --purple: #A57FB2FF;
    --darkPurple: #981e97FF;
}


a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}
